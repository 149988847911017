.alert{
    animation-name: animAlert;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
	animation-fill-mode: both;
}

.selection {
	outline: 0.1rem solid var(--bs-primary);
}

@keyframes animAlert {
	from {
		background-color: var(--bs-warning);
	}
	to {
		background-color: var(--bs-light);
	}
}