* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.verticalCenter {
	display: flex;
	flex-wrap: wrap;
	align-content: center;
	justify-content: center;

	form {
		label {
			font-size: 1.1rem;
		}
		width: 80%;
	}

	@media(max-width: 720px) {
		form {
			width: 100%;
		}
	}
}

.verticalCenterFull {
	display: flex;
	flex-wrap: wrap;
	align-content: center;
	justify-content: center;

	form {
		label {
			font-size: 1.1rem;
		}
		width: 50%;
	}

	@media(max-width: 720px) {
		form {
			width: 100%;
		}
	}

	@media(max-width: 900px) {
		.card {
			width: 100%;
		}		
	}
}

.tabelaWrapper {
	display: block;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
}

.destacavel {
	&:hover{
		filter: brightness(95%);
	}
}